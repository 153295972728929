import React, { useState, useEffect } from "react";
import { useContext } from "react";
import Angle from "../components/Drawing/Types/Angle";
import Arrow from "../components/Drawing/Types/Arrow";
import Circle from "../components/Drawing/Types/Circle";
import Line from "../components/Drawing/Types/Line";
import Magnify from "../components/Drawing/Types/Maginify";
import Pen from "../components/Drawing/Types/Pen";
import { UserContext } from "./UserContext";
let outputData;
const OutputContext = React.createContext([{}, () => {}]);

const OutputProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [items, setItems] = useState([]);
  const [wsStatus, setWSStatus] = useState(-1);
  const { loggedIn, user } = useContext(UserContext);
  useEffect(() => {
    connect();
  }, []);

  function connect() {
    console.log("CG connecting to server");
    try {
      setWS(new WebSocket("ws://" + window.location.hostname + ":8089"));
    } catch (err) {}
  }

  useEffect(() => {
    if (ws) {
      let timeout;
      let interval;

      ws.onopen = () => {
        setWSStatus(1);
        console.log("CG on open");

        // console.log("connected");
        clearInterval(interval);
        interval = setInterval(() => {
          // sendData({
          //   keep_alive: Date.now(),
          //   group: window.id,
          // });
        }, 20000);

        // sendData({
        //   control: true,
        //   group: window.id,
        // });
      };
      ws.onmessage = (evt) => {
        // console.log("CG on message");
        try {
          let data = JSON.parse(evt.data);

          data = data?.map((item) => {
            if (item.type === "pen") {
              return new Pen({ ...item, context: window.drawingCTX });
            } else if (item.type === "circle") {
              return new Circle({ ...item, context: window.drawingCTX });
            } else if (item.type === "arrow") {
              let arrow = new Arrow({ ...item, context: window.drawingCTX });
              arrow.endPoint = item.endPoint;
              return arrow;
            } else if (item.type === "line") {
              let line = new Line({ ...item, context: window.drawingCTX });
              line.endPoint = item.endPoint;
              return line;
            } else if (item.type === "zoom") {
              return new Magnify({ ...item, context: window.drawingCTX });
            } else if (item.type === "angle") {
              let angle = new Angle({ ...item, context: window.drawingCTX });
              angle.secondPoint = item.secondPoint;
              angle.x = item.x;
              angle.y = item.y;
              return angle;
            }
          });

          setItems(data);
        } catch (err) {
          debugger;
        }
      };
      ws.onerror = (err) => {
        console.log("CG on message");
        ws.close();
      };
      ws.onclose = (data) => {
        setWSStatus(0);
        console.log("CG on close");
        clearTimeout(timeout);
        clearInterval(interval);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws]);

  function sendData(data) {
    try {
      if (ws) {
        ws.send(JSON.stringify(data));
      }
    } catch (err) {}
  }

  return (
    <OutputContext.Provider
      value={{
        sendData,
        items,
      }}
    >
      {props.children}
    </OutputContext.Provider>
  );
};

export { OutputContext, OutputProvider };
