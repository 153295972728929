import React, { useEffect, useRef, useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import Circle from "./Types/Circle";
import Pen from "./Types/Pen";
import Arrow from "./Types/Arrow";
import Line from "./Types/Line";
import Magnify from "./Types/Maginify";
import Angle from "./Types/Angle";
import { OutputContext } from "../../contexts/OutputContext";

const Canvas = styled.canvas`
  position: absolute;
  z-index: 100;
`;

let mouse = { x: 0, y: 0 };
let mouseDown = false;
let touchDown = false;
let previous = "";
export default function DrawingCanvas({
  items,
  setItems,
  currentItem,
  setCurrentItem,
}) {
  const themeContext = useContext(ThemeContext);
  const { sendData } = useContext(OutputContext);

  const canvasRef = useRef(null);

  useEffect(() => {
    var canvas = document.getElementById("drawing");
    canvas.width = 1920;
    canvas.height = 1080;
    const offscreenCanvas = canvas.transferControlToOffscreen();

    // window.drawingCTX = canvas.getContext("2d", { willReadFrequently: true });
    // window.drawingCTX.shadowColor = "rgba( 0, 0, 0, 0.6 )";
    // window.drawingCTX.shadowOffsetX = 3;
    // window.drawingCTX.shadowOffsetY = 3;
    // window.drawingCTX.shadowBlur = 4;

    window.worker = new Worker("worker.js");
    window.worker.postMessage({ msg: "offscreen", canvas: offscreenCanvas }, [
      offscreenCanvas,
    ]);
    // requestAnimationFrame(render);
  }, []);

  useEffect(() => {
    window.global_items = items;
    window.worker.postMessage({ msg: "items", items: JSON.stringify(items) });
    if (document.location.pathname === "/") {
      sendData(window.global_items);
    }
  }, [items]);

  function render() {
    let current = JSON.stringify(window.global_items);
    if (previous !== current) {
      window.drawingCTX.clearRect(
        0,
        0,
        window.drawingCTX.canvas.width,
        window.drawingCTX.canvas.height
      );
      // console.log(window.global_items.length);
      window.global_items.forEach((item) => {
        item.draw();
      });
      if (document.location.pathname === "/") {
        sendData(window.global_items);
        previous = current;
      }
    }

    window.requestAnimationFrame(render);
  }

  useEffect(() => {
    let canvasElement = canvasRef.current;

    function mouseMove() {
      let newItems = [...items];

      if (newItems[newItems.length - 1].type === "pen") {
        newItems[newItems.length - 1].points.push({ x: mouse.x, y: mouse.y });
      } else if (newItems[newItems.length - 1].type === "circle") {
        newItems[newItems.length - 1].scale =
          (Math.abs(newItems[newItems.length - 1].points.x - mouse.x) +
            newItems[newItems.length - 1].size) /
          newItems[newItems.length - 1].size;
      } else if (
        newItems[newItems.length - 1].type === "arrow" ||
        newItems[newItems.length - 1].type === "line"
      ) {
        newItems[newItems.length - 1].endPoint = { x: mouse.x, y: mouse.y };
      } else if (newItems[newItems.length - 1].type === "angle") {
        newItems[newItems.length - 1].x = mouse.x;
        newItems[newItems.length - 1].y = mouse.y;
      }
      setItems(newItems);
    }

    if (canvasElement && currentItem) {
      canvasElement.addEventListener("mousemove", mouseMove, false);
      canvasElement.addEventListener("touchmove", mouseMove, false);
    }

    return () => {
      if (canvasElement) {
        canvasElement.removeEventListener("mousemove", mouseMove, false);
        canvasElement.removeEventListener("touchmove", mouseMove, false);
      }
    };
  }, [currentItem]);

  useEffect(() => {
    let canvasElement = canvasRef.current;

    function mouseDown(e) {
      if (e.changedTouches) {
        touchDown = true;
        var rect = e.target.getBoundingClientRect();
        var x = e.changedTouches[0].clientX - rect.left; //x position within the element.
        var y = e.changedTouches[0].clientY - rect.top; //y position within the element.
        x = x * (1920 / rect.width);
        y = y * (1080 / rect.height);
        mouse.x = x;
        mouse.y = y;
        let newItems = [...items];
        newItems[newItems.length - 1].secondPoint = { x: mouse.x, y: mouse.y };
        setItems(newItems);
      } else if (!touchDown) {
        let newItems = [...items];
        newItems[newItems.length - 1].secondPoint = { x: mouse.x, y: mouse.y };
        setItems(newItems);
      }
    }

    if (canvasElement && currentItem && currentItem.type === "angle") {
      canvasElement.addEventListener("touchstart", mouseDown, false);
      canvasElement.addEventListener("mousedown", mouseDown, false);
    }

    return () => {
      if (canvasElement) {
        canvasElement.removeEventListener("mousedown", mouseDown, false);
        canvasElement.removeEventListener("touchstart", mouseDown, false);
      }
    };
  }, [currentItem]);

  function onDown() {
    if (window.drawType === "pen") {
      let pen = new Pen({
        points: [{ x: mouse.x, y: mouse.y }],
        context: window.drawingCTX,
        colour: themeContext.scrubber,
      });
      setItems([...items, pen]);
      setCurrentItem(pen);
      //window.drawingCTX.beginPath();
      //window.drawingCTX.moveTo(mouse.x, mouse.y);
      // e.currentTarget.addEventListener("mousemove", onPaint, false);
    } else if (window.drawType === "circle") {
      let circle = new Circle({
        points: { x: mouse.x, y: mouse.y },
        scale: 1,
        context: window.drawingCTX,
        colour: themeContext.scrubber,
      });
      setItems([...items, circle]);
      setCurrentItem(circle);
    } else if (window.drawType === "arrow") {
      let arrow = new Arrow({
        startPoint: { x: mouse.x, y: mouse.y },
        context: window.drawingCTX,
        colour: themeContext.scrubber,
      });
      setItems([...items, arrow]);
      setCurrentItem(arrow);
    } else if (window.drawType === "line") {
      let line = new Line({
        startPoint: { x: mouse.x, y: mouse.y },
        context: window.drawingCTX,
        colour: themeContext.scrubber,
      });
      setItems([...items, line]);
      setCurrentItem(line);
    } else if (window.drawType === "zoom") {
      let magnify = new Magnify({
        points: { x: mouse.x, y: mouse.y },
        scale: 1,
        context: window.drawingCTX,
        colour: themeContext.scrubber,
      });
      setItems([...items, magnify]);
      setCurrentItem(magnify);
    } else if (window.drawType === "angle") {
      if (!currentItem) {
        let angle = new Angle({
          startPoint: { x: mouse.x, y: mouse.y },
          scale: 1,
          context: window.drawingCTX,
          colour: themeContext.scrubber,
        });
        setItems([...items, angle]);
        setCurrentItem(angle);
      }
    }
  }
  return (
    // <DrawingCanvasDiv>
    <Canvas
      ref={canvasRef}
      id="drawing"
      onTouchStart={(e) => {
        touchDown = true;
        var rect = e.target.getBoundingClientRect();
        var x = e.changedTouches[0].clientX - rect.left; //x position within the element.
        var y = e.changedTouches[0].clientY - rect.top; //y position within the element.
        x = x * (1920 / rect.width);
        y = y * (1080 / rect.height);
        mouse.x = x;
        mouse.y = y;
        onDown();
      }}
      onTouchMove={(e) => {
        var rect = e.target.getBoundingClientRect();
        var x = e.changedTouches[0].clientX - rect.left; //x position within the element.
        var y = e.changedTouches[0].clientY - rect.top; //y position within the element.
        x = x * (1920 / rect.width);
        y = y * (1080 / rect.height);
        mouse.x = x;
        mouse.y = y;
      }}
      onTouchEnd={(e) => {
        if (
          currentItem &&
          (currentItem.type !== "angle" || currentItem.secondPoint)
        ) {
          setCurrentItem(null);
        }

        mouseDown = false;
      }}
      onMouseMove={(e) => {
        if (!touchDown) {
          var rect = e.target.getBoundingClientRect();
          var x = e.clientX - rect.left; //x position within the element.
          var y = e.clientY - rect.top; //y position within the element.
          x = x * (1920 / rect.width);
          y = y * (1080 / rect.height);
          mouse.x = x;
          mouse.y = y;
        }
      }}
      onMouseDown={(e) => {
        if (!touchDown) {
          onDown();
        }
        touchDown = false;
      }}
      onMouseUp={(e) => {
        // return;
        if (
          currentItem &&
          (currentItem.type !== "angle" || currentItem.secondPoint)
        ) {
          setCurrentItem(null);
        }

        mouseDown = false;
      }}
    ></Canvas>
  );
}
