import React, { useState, useContext } from "react";
import styled, { css, ThemeContext } from "styled-components";
import Video from "./Video";
import { FiCircle, FiTriangle, FiSearch, FiEdit2 } from "react-icons/fi";
import { FaLongArrowAltRight } from "react-icons/fa";
import { AiOutlineLine } from "react-icons/ai";
import HyperLogo from "../../Images/HyperLogo.png";
const SidebarDiv = styled.div`
  width: 100px;
  position: fixed;
  display: flex;
  right: 0px;

  border-radius: 4px;
  padding: 0.5em;
  background-color: ${(props) => props.theme.sidebar};
  flex-direction: column;
  color: ${(props) => props.theme.tool};
  height: 100vh;
  align-items: center;
`;

const Videos = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 14px;
  margin-top: 0px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  padding-bottom: 14px;
  min-height: 184px;
  overflow-y: auto;
`;

const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Control = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100px;
  font-size: 3.5em;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.selected &&
    css`
      color: ${(props) => "#db0a41"};
    `}
`;
const Row = styled.div`
  display: flex;
  width: 100%;
`;

const Logo = styled.img``;
export default function Sidebar({ undo, clear }) {
  const [selectedControl, setSelectedControl] = useState(0);

  return (
    <SidebarDiv>
      {/* <Logo src={HyperLogo} alt="Hyper Logo" /> */}
      <Row>
        <Controls>
          <Control
            selected={selectedControl === 0}
            onClick={() => {
              setSelectedControl(0);
              window.drawType = "circle";
            }}
          >
            <FiCircle></FiCircle>
          </Control>
          <Control
            selected={selectedControl === 1}
            onClick={() => {
              setSelectedControl(1);
              window.drawType = "arrow";
            }}
          >
            <FaLongArrowAltRight></FaLongArrowAltRight>
          </Control>
          <Control
            selected={selectedControl === 2}
            onClick={() => {
              setSelectedControl(2);
              window.drawType = "rectangle";
              window.drawType = "line";
            }}
          >
            <AiOutlineLine></AiOutlineLine>
          </Control>
          <Control
            selected={selectedControl === 3}
            onClick={() => {
              setSelectedControl(3);
              window.drawType = "angle";
            }}
          >
            <FiTriangle></FiTriangle>
          </Control>
          {/* <Control
            selected={selectedControl === 4}
            onClick={() => {
              setSelectedControl(4);
              window.drawType = "zoom";
            }}
          >
            <FiSearch></FiSearch>
          </Control> */}
          <Control
            selected={selectedControl === 5}
            onClick={() => {
              setSelectedControl(5);
              window.drawType = "pen";
            }}
          >
            <FiEdit2></FiEdit2>
          </Control>
        </Controls>
      </Row>
      <Row>
        <Controls style={{ marginTop: "55px", marginBottom: "50px" }}>
          <Control
            style={{ fontSize: "2em" }}
            onClick={() => {
              setSelectedControl();
              window.drawType = "";
              clear();
            }}
          >
            CLEAR
          </Control>
          <Control style={{ fontSize: "2em" }} onClick={undo}>
            UNDO
          </Control>
        </Controls>
      </Row>
    </SidebarDiv>
  );
}
