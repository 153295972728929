import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "./UserContext";
let inputData;
const VideoContext = React.createContext([{}, () => {}]);

const VideoProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);
  const { loggedIn, user } = useContext(UserContext);
  useEffect(() => {
    connect();
  }, []);

  function connect() {
    console.log("CG connecting to server");
    try {
      setWS(new WebSocket("ws://" + window.location.hostname + ":8088"));
    } catch (err) {}
  }

  useEffect(() => {
    if (ws) {
      let timeout;
      let interval;
      ws.binaryType = "arraybuffer";
      ws.onopen = () => {
        setWSStatus(1);
        console.log("CG on open");

        // console.log("connected");
        clearInterval(interval);
        interval = setInterval(() => {
          sendData({
            keep_alive: Date.now(),
            group: window.id,
          });
        }, 20000);

        sendData({
          control: true,
          group: window.id,
        });
      };
      ws.onmessage = (evt) => {
        // console.log("CG on message");
        if (window.input_ctx && evt.data) {
          window.data = evt.data;
          requestAnimationFrame(() => {
            var bytearray = new Uint8Array(window.data);

            if (!inputData) {
              inputData = window.input_ctx.createImageData(
                window.input_canvas.width,
                window.input_canvas.height
              );
            }
            inputData.data.set(bytearray, 0);
            // if (window.input_ctx) {
            //   createImageBitmap(inputData).then((bitmap) => {
            //     window.input_ctx.drawImage(bitmap, 0, 0);
            //   });
            // }

            window.input_ctx.putImageData(inputData, 0, 0);
          });
        }
      };
      ws.onerror = (err) => {
        console.log("CG on message");
        ws.close();
      };
      ws.onclose = (data) => {
        setWSStatus(0);
        console.log("CG on close");
        clearTimeout(timeout);
        clearInterval(interval);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws]);

  function sendData(data) {
    if (ws) {
      ws.send(JSON.stringify(data));
    }
  }

  return (
    <VideoContext.Provider
      value={{
        sendData,
      }}
    >
      {props.children}
    </VideoContext.Provider>
  );
};

export { VideoContext, VideoProvider };
