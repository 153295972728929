export default function Line({ startPoint, context, colour }) {
  let _endPoint = startPoint;
  let _showPerspective = false;
  let _colour = colour;
  return {
    type: "line",
    startPoint: startPoint,
    get colour() {
      return _colour;
    },
    set colour(val) {
      _colour = val;
    },
    get endPoint() {
      return _endPoint;
    },
    set endPoint(val) {
      _endPoint = val;
    },
    draw: function () {
      let startX = startPoint.x;
      let startY = startPoint.y;
      let x = _endPoint.x;
      let y = _endPoint.y;
      context.lineWidth = 10;
      context.strokeStyle = colour;
      context.lineCap = "round";
      context.beginPath();
      context.moveTo(startX, startY);
      context.strokeStyle = colour;
      context.lineTo(x, y);
      context.stroke();
      context.closePath();

      if (_showPerspective) {
        // Determine line lengths
        var xlen = x - startX;
        var ylen = y - startY;

        // Determine hypotenuse length
        var hlen = Math.sqrt(Math.pow(xlen, 2) + Math.pow(ylen, 2));
        var smallerLen = 500;

        // Determine the ratio between they shortened value and the full hypotenuse.
        var ratio = smallerLen / hlen;

        var smallerXLen = xlen * ratio;
        var smallerYLen = ylen * ratio;

        // The new X point is the starting x plus the smaller x length.
        var smallerX = x + smallerXLen;

        // Same goes for the new Y.
        var smallerY = y + smallerYLen;

        context.strokeStyle = "rgba(255, 0, 0, .2)";

        context.moveTo(x, y);
        context.lineTo(smallerX, smallerY);
        context.stroke();
        context.closePath();
      }
    },
  };
}
