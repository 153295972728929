export default function Angle({ startPoint, context, colour }) {
  let _secondPoint;
  let _colour = colour;
  let _x = 0;
  let _y = 0;
  let _showPerspective = false;
  function radians_to_degrees(radians) {
    var pi = Math.PI;
    return radians * (180 / pi);
  }

  return {
    type: "angle",
    startPoint: startPoint,
    get secondPoint() {
      return _secondPoint;
    },
    set secondPoint(val) {
      _secondPoint = val;
    },
    get x() {
      return _x;
    },
    set x(val) {
      _x = val;
    },
    get y() {
      return _y;
    },
    set y(val) {
      _y = val;
    },
    get colour() {
      return _colour;
    },
    set colour(val) {
      _colour = val;
    },
    draw: function () {
      if (!_secondPoint) {
        // context.beginPath();
        // context.arc(startPoint.x, startPoint.y, 10, 0, 360, false);
        // context.fillStyle = "#1f212b";
        // context.lineWidth = 10;
        // context.fill();

        context.beginPath();
        context.arc(startPoint.x, startPoint.y, 10, 0, 360, false);
        context.fillStyle = colour;
        context.lineWidth = 5;
        context.fill();
        return;
      }
      let secondX = startPoint.x;
      let secondY = startPoint.y;
      let startX = _secondPoint.x;
      let startY = _secondPoint.y;
      let x = _x;
      let y = _y;

      //FIRST LINE
      // window.drawingCTX.lineWidth = 10;
      // window.drawingCTX.beginPath();
      // window.drawingCTX.moveTo(secondX, secondY);
      // window.drawingCTX.strokeStyle = "#1f212b";
      // window.drawingCTX.lineTo(x, y);
      // window.drawingCTX.stroke();
      // window.drawingCTX.closePath();

      window.drawingCTX.lineWidth = 5;
      window.drawingCTX.beginPath();
      window.drawingCTX.moveTo(secondX, secondY);
      window.drawingCTX.strokeStyle = colour;
      window.drawingCTX.lineTo(x, y);
      window.drawingCTX.stroke();
      window.drawingCTX.closePath();

      //SECOND LINE
      // window.drawingCTX.lineWidth = 10;
      // window.drawingCTX.beginPath();
      // window.drawingCTX.strokeStyle = "#1f212b";
      // window.drawingCTX.moveTo(startX, startY);
      // window.drawingCTX.lineTo(secondX, secondY);
      // window.drawingCTX.stroke();
      // window.drawingCTX.closePath();

      window.drawingCTX.lineWidth = 5;
      window.drawingCTX.beginPath();
      window.drawingCTX.strokeStyle = colour;
      window.drawingCTX.moveTo(startX, startY);
      window.drawingCTX.lineTo(secondX, secondY);
      window.drawingCTX.stroke();
      window.drawingCTX.closePath();

      //FIRST DOT
      // context.beginPath();
      // context.arc(startPoint.x, startPoint.y, 12, 0, 360, false);
      // context.fillStyle = "#1f212b";
      // context.lineWidth = 10;
      // context.fill();

      context.beginPath();
      context.arc(startPoint.x, startPoint.y, 10, 0, 360, false);
      context.fillStyle = colour;
      context.lineWidth = 5;
      context.fill();

      //SECOND DOT
      // context.beginPath();
      // context.arc(_secondPoint.x, _secondPoint.y, 12, 0, 360, false);
      // context.fillStyle = "#1f212b";
      // context.lineWidth = 10;
      // context.fill();

      context.beginPath();
      context.arc(_secondPoint.x, _secondPoint.y, 10, 0, 360, false);
      context.fillStyle = colour;
      context.lineWidth = 5;
      context.fill();

      //THIRD DOT
      // context.beginPath();
      // context.arc(_x, _y, 12, 0, 360, false);
      // context.fillStyle = "#1f212b";
      // context.lineWidth = 5;
      // context.fill();

      context.beginPath();
      context.arc(_x, _y, 10, 0, 360, false);
      context.fillStyle = colour;
      context.lineWidth = 5;
      context.fill();

      //ANGLE TEXT
      var a = secondX - startX;
      var b = secondY - startY;

      let greenSide = Math.sqrt(a * a + b * b);
      // window.drawingCTX.fillStyle = "#db0a41";
      // window.drawingCTX.font = "48px Tahoma";
      // window.drawingCTX.fillText(greenSide, 100, 100);

      a = secondX - x;
      b = secondY - y;

      let redSide = Math.sqrt(a * a + b * b);
      // window.drawingCTX.fillStyle = "#db0a41";
      // window.drawingCTX.font = "48px Tahoma";
      // window.drawingCTX.fillText(redSide, 100, 60);

      a = startX - x;
      b = startY - y;

      let blueSide = Math.sqrt(a * a + b * b);
      // window.drawingCTX.fillStyle = "#db0a41";
      // window.drawingCTX.font = "48px Tahoma";
      // window.drawingCTX.fillText(blueSide, 100, 140);

      let angle1 =
        (greenSide * greenSide + redSide * redSide - blueSide * blueSide) /
        (2 * greenSide * redSide);
      angle1 = Math.acos(angle1);

      if (!angle1) {
        angle1 = 0;
      }
      window.drawingCTX.fillStyle = colour;
      window.drawingCTX.font = "48px Tahoma";

      window.drawingCTX.fillText(
        parseInt(radians_to_degrees(angle1)) + "°",
        secondX + 50,
        secondY
      );
    },
  };
}
