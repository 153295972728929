export default function Circle({ type, points, scale, context, colour }) {
  let _scale = scale;
  let _colour = colour;
  return {
    type: "circle",
    points: points,
    size: 50,
    get colour() {
      return _colour;
    },
    set colour(val) {
      _colour = val;
    },
    get scale() {
      return _scale;
    },
    set scale(val) {
      _scale = val;
    },
    draw: function () {
      // context.beginPath();
      // context.arc(points.x, points.y, 50 * _scale, 0, 360, false);
      // context.strokeStyle = "#1f212b";
      // context.lineWidth = 10;
      // context.stroke();

      context.beginPath();
      context.arc(points.x, points.y, 50 * _scale, 0, 360, false);
      // Create gradient
      // var gradient = context.createLinearGradient(
      //   points.x,
      //   points.y,
      //   points.x + 40,
      //   points.y + 80
      // );
      // gradient.addColorStop("0", "#db0a41");
      // gradient.addColorStop("1.0", "#1f212b");
      // Fill with gradient
      //  context.strokeStyle = gradient;
      context.strokeStyle = colour;

      context.lineWidth = 5;
      context.stroke();
    },
  };
}
