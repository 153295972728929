export default function Arrow({ startPoint, context, colour }) {
  let _endPoint;

  let _colour = colour;
  return {
    type: "arrow",
    startPoint: startPoint,
    get colour() {
      return _colour;
    },
    set colour(val) {
      _colour = val;
    },
    get endPoint() {
      return _endPoint;
    },
    set endPoint(val) {
      _endPoint = val;
    },

    draw: function () {
      let tox = _endPoint?.x;
      let toy = _endPoint?.y;
      let fromx = startPoint.x;
      let fromy = startPoint.y;

      // context.lineWidth = 15;
      // context.strokeStyle = "#1f212b";
      // context.lineCap = "round";
      // context.beginPath();
      // var headlen = 50; // length of head in pixels
      // var dx = tox - fromx;
      // var dy = toy - fromy;
      // var angle = Math.atan2(dy, dx);
      // context.moveTo(fromx, fromy);
      // context.lineTo(tox, toy);
      // context.moveTo(tox, toy);
      // context.lineTo(
      //   tox - headlen * Math.cos(angle - Math.PI / 6),
      //   toy - headlen * Math.sin(angle - Math.PI / 6)
      // );
      // context.moveTo(tox, toy);
      // context.lineTo(
      //   tox - headlen * Math.cos(angle + Math.PI / 6),
      //   toy - headlen * Math.sin(angle + Math.PI / 6)
      // );
      // context.stroke();

      context.lineWidth = 10;
      context.strokeStyle = colour;
      context.lineCap = "round";
      context.beginPath();
      var headlen = 50; // length of head in pixels
      var dx = tox - fromx;
      var dy = toy - fromy;
      var angle = Math.atan2(dy, dx);
      context.moveTo(fromx, fromy);
      context.lineTo(tox, toy);
      context.moveTo(tox, toy);
      context.lineTo(
        tox - headlen * Math.cos(angle - Math.PI / 6),
        toy - headlen * Math.sin(angle - Math.PI / 6)
      );
      context.moveTo(tox, toy);
      context.lineTo(
        tox - headlen * Math.cos(angle + Math.PI / 6),
        toy - headlen * Math.sin(angle + Math.PI / 6)
      );
      context.stroke();
    },
  };
}
