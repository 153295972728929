export default function Magnify({ points, scale, context, colour }) {
  let _scale = scale;
  let _colour = colour;
  let _showPerspective = false;

  return {
    type: "magnify",
    points: points,
    size: 150,
    get scale() {
      return _scale;
    },
    set scale(val) {
      _scale = val;
    },
    get colour() {
      return _colour;
    },
    set colour(val) {
      _colour = val;
    },
    draw: function () {
      context.save();
      context.beginPath();
      console.log();
      context.arc(points.x, points.y, 150 * scale, 0, Math.PI * 2, true);
      context.closePath();
      context.clip();

      context.drawImage(
        window.showing_input ? window.input_ctx.canvas : window.ctx.canvas,
        points.x * 0.66666666666 - 50, //x of where source image
        points.y * 0.66666666666 - 50, //y of where source image
        100, //width of source image
        100, //height of source image
        points.x - 150, //x of where image drawn
        points.y - 150, //y of where image drawn
        300, //width of image drawn
        300 //height of image drawn
      );

      context.strokeStyle = colour;
      context.lineWidth = 8;
      context.stroke();
      context.beginPath();
      context.arc(points.x, points.y, 150 * scale, 0, Math.PI * 2, true);
      context.clip();
      context.closePath();
      context.restore();
    },
  };
}
