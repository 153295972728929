import React, { useEffect } from "react";
import styled from "styled-components";

const VideoCanvas = styled.canvas`
  width: 100%;
  border-radius: 4px;
  background-color: #181d25;
`;
const Main = styled.div`
  width: ${(props) => (props.theme.layout === 1 ? "45%" : "44%")};
  display: "flex";
  margin: 0.4em;
`;

export default function Video({ video, selectedVideo, decoder, clear }) {
  useEffect(() => {
    let playingInterval;
    var canvas = document.getElementById(video.replace(".mov", ""));
    canvas.width = window?.config?.videos?.format === 720 ? 1280 : 1920;
    canvas.height = window?.config?.videos?.format === 720 ? 720 : 1080;

    var ctx = canvas.getContext("2d");
    const imageData = ctx.createImageData(
      window?.config?.videos?.format === 720 ? 1280 : 1920,
      window?.config?.videos?.format === 720 ? 720 : 1080
    );
    playingInterval = setInterval(() => {
      if (decoder.thumbnail && decoder.thumbnail.videoData) {
        imageData.data.set(
          decoder.thumbnail.videoData.slice(0, imageData.data.length),
          0
        );
        ctx.putImageData(imageData, 0, 0);
        clearInterval(playingInterval);
      }
    }, 10);
    // interval = setInterval(() => {

    //   if (window.decoders.find(vid => vid.name === video)) {
    //     setPercentage(window.decoders.find(vid => vid.name === video).decoder.percentage)
    //     if (window.decoders.find(vid => vid.name === video).decoder.finished) {
    //       setFinished(true)
    //       clearInterval(interval)
    //     }
    //   } else {
    //     clearInterval(interval)
    //   }

    // }, 500)
  }, []);

  return (
    <Main
      onClick={() => {
        window.showing_input = false;
        window.frameNumber = 5;
        window.frameNumber_prev = -1;
        window.audioNumber = 0;
        window.playing = false;

        clear();
        selectedVideo(video);
      }}
    >
      {/* {!finished &&
        <div style={{
          width: '90%', height: '3px', position: 'absolute', backgroundColor: '#0e1219', marginTop: '40px', marginLeft: '5px', borderRadius: '8px', overflow: 'hidden', opacity: '.6'
        }}>
          <div style={{ width: percentage + 'px', height: '3px', position: 'absolute', backgroundColor: '#fff' }}></div>
        </div >
      } */}

      <VideoCanvas id={video.replace(".mov", "")}></VideoCanvas>
    </Main>
  );
}
