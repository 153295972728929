import React, { useState, useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";

const LOGGED_IN = gql`
  query LoggedIn {
    me {
      _id
      username
      token
      roles {
        name
      }
      features {
        name
      }
    }
  }
`;

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      _id
    }
  }
`;

const UserContext = React.createContext([{}, () => {}]);

const UserProvider = (props) => {
  const { data: loggedInUser } = useQuery(LOGGED_IN, {
    fetchPolicy: "network-first",
  });
  const [loginMutation, { data }] = useMutation(LOGIN, {
    onCompleted: (data) => {
      localStorage.setItem("user-id", data.login._id);
      localStorage.setItem("token", data.login.token);

      window.groups = [
        {
          name: data.login._id,
          status: 1,
        },
      ];

      setUserToken(data.login.token);
      setLoggedIn(true);
    },
  });
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState();
  const [signinError, setSigninError] = useState();
  const [userCollection, setUserCollection] = useState([]);
  const [token, setToken] = useState();

  useEffect(() => {
    if (loggedInUser?.me) {
      window.groups = [
        {
          name: loggedInUser.me._id,
          status: 1,
        },
      ];

      setUserToken(loggedInUser.me.token);
      setLoggedIn(true);
    }
  }, [loggedInUser]);

  function login({ username, password }) {
    loginMutation({ variables: { username, password } });
  }

  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  function setUserToken(token) {
    let new_user = parseJwt(token);

    if (new_user?.features?.find((f) => f.name === "multi_user")) {
      window.random = "_" + Math.floor(Math.random() * 10000000);
    } else {
      window.random = "";
    }

    setUser(new_user);
    setToken(token);
  }

  function signOut() {
    try {
      var football_iframe =
        window.document.getElementById("football").contentWindow;

      football_iframe.postMessage(
        JSON.stringify({
          event: "logout",
        }),
        process.env.REACT_APP_FOOTBALL_UI || window.ENV?.REACT_APP_FOOTBALL_UI
      );
    } catch (err) {
      console.log("couldnt logout football");
    }
    try {
      var rugby_iframe = window.document.getElementById("rugby").contentWindow;

      rugby_iframe.postMessage(
        JSON.stringify({
          event: "logout",
        }),
        process.env.REACT_APP_RUGBY_UI || window.ENV?.REACT_APP_RUGBY_UI
      );
    } catch (err) {
      console.log("couldnt logout rugby");
    }
    try {
      var management_iframe =
        window.document.getElementById("management").contentWindow;

      management_iframe.postMessage(
        JSON.stringify({
          event: "logout",
        }),
        window.ENV?.REACT_APP_MANAGEMENT_UI ||
          process.env.REACT_APP_MANAGEMENT_UI
      );
    } catch (err) {
      console.log("couldnt logout management");
    }
    try {
      var mlb_iframe = window.document.getElementById("mlb").contentWindow;

      mlb_iframe.postMessage(
        JSON.stringify({
          event: "logout",
        }),
        process.env.REACT_APP_MLB_UI || window.ENV?.REACT_APP_MLB_UI
      );
    } catch (err) {
      console.log("couldnt logout MLB");
    }
    try {
      var golf_iframe = window.document.getElementById("golf").contentWindow;

      golf_iframe.postMessage(
        JSON.stringify({
          event: "logout",
        }),
        process.env.REACT_APP_GOLF_UI || window.ENV?.REACT_APP_GOLF_UI
      );
    } catch (err) {
      console.log("couldnt logout golf");
    }

    localStorage.removeItem("user-id");
    localStorage.removeItem("token");
    window.location.reload();
  }

  return (
    <UserContext.Provider
      value={{
        user,
        loggedIn,
        userCollection,
        login,
        signinError,
        signOut,
        token,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
