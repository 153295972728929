export default function Pen({ type, points, context, colour }) {
  let _colour = colour;
  return {
    type: "pen",
    points: points,
    get colour() {
      return _colour;
    },
    set colour(val) {
      _colour = val;
    },
    draw: function () {
      var p1 = points[0];
      var p2 = points[1];
      context.lineWidth = 10;
      context.strokeStyle = colour;
      context.lineCap = "round";
      context.beginPath();

      for (var i = 1, len = points.length; i < len; i++) {
        // we pick the point between pi+1 & pinp+2 as the
        // end point and p1 as our control point
        var midPoint = midPointBtw(p1, p2);
        context.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
        p1 = points[i];
        p2 = points[i + 1];
      }

      context.stroke();
    },
  };

  function midPointBtw(p1, p2) {
    return {
      x: p1.x + (p2.x - p1.x) / 2,
      y: p1.y + (p2.y - p1.y) / 2,
    };
  }
}
